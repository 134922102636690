import { InMemoryCache, makeVar } from '@apollo/client'
import { getUserToken } from './utils/authentication'

export const isLoggedInVar = makeVar<boolean>(getUserToken() ? getUserToken().length > 0 : false)

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            let isLoggedIn = false

            const userToken = getUserToken()

            if (userToken && userToken.length > 0) {
              isLoggedIn = true
            } else {
              isLoggedIn = isLoggedInVar()
            }

            return isLoggedIn
          },
        },
      },
    },
  },
  addTypename: true,
})

export default cache
